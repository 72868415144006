// This file is declare as sideeffect-ful in package.json
/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import { ApolloWebSocketDebugger } from "./util/ApolloWebsocketManager";
import { CONNECTION_LOG } from "./util/ConnectionLog";
import { ConnectionManager } from "./util/ConnectionManager";
import { createApolloClient } from "./util/createApolloClient";

export const connectionManager = new ConnectionManager();
(window as any).__HEX_CONNECTION_MANAGER__ = connectionManager;
export const apolloWebsocketDebugger = new ApolloWebSocketDebugger();
export const client = createApolloClient({
  onConnectionStatusChange: (newStatus) =>
    connectionManager.broadcast(newStatus),
  onWsClientCreated: (wsClient) =>
    apolloWebsocketDebugger.setWsClient(wsClient),
  onWsPing: () => apolloWebsocketDebugger.handleWsPing(),
  onWsPong: () => apolloWebsocketDebugger.handleWsPong(),
});

// connection manager dedupes, so we prefer to use it
connectionManager.subscribe((newStatus) => {
  CONNECTION_LOG.push({
    status: newStatus,
    time: new Date(),
  });
});
