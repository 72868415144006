import { useCallback } from "react";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import {
  ScopeItemMP,
  appSessionMPSelectors,
} from "../../redux/slices/appSessionMPSlice";
import { useSessionContext } from "../../util/sessionContext";

type UseScopeSelectorResult<T, S> = S extends false ? T : T | undefined;

type EqualityFnType<T, S> = (
  left: UseScopeSelectorResult<T, S>,
  right: UseScopeSelectorResult<T, S>,
) => boolean;

export interface UseScopeSelectorArgs<T, S extends boolean> {
  selector: (scope: Record<string, ScopeItemMP | undefined>) => T;
  equalityFn?: EqualityFnType<T, S>;
  /**
   * We generally do not want to include hidden scope items, so this defaults to
   * false.
   */
  includeHidden?: boolean;
  /**
   * @default false
   */
  safe?: S;
}

export function useScopeSelector<T, S extends boolean = false>({
  equalityFn,
  includeHidden = false,
  safe,
  selector,
}: UseScopeSelectorArgs<T, S>): UseScopeSelectorResult<T, S> {
  const { appSessionId } = useSessionContext({ safe }) ?? {
    appSessionId: undefined,
  };

  return useSelector((state) => {
    // if appSessionId is null and we haven't errored above, then `safe` must be true.
    if (appSessionId == null) {
      return undefined as UseScopeSelectorResult<T, S>;
    }
    const scopeSelectors =
      appSessionMPSelectors.getScopeSelectors(appSessionId);
    const scopeState = includeHidden
      ? scopeSelectors.selectEntitiesWithHidden(state)
      : scopeSelectors.selectEntities(state);

    if (scopeState == null) {
      throw new Error(`Missing scope state for app session: ${appSessionId}`);
    }

    return selector(scopeState) as UseScopeSelectorResult<T, S>;
  }, equalityFn);
}

export interface UseScopeGetterArgs<A extends unknown[], T> {
  selector?: (
    scope: { [name: string]: ScopeItemMP | undefined },
    ...args: A
  ) => T;
  includeHidden?: boolean;
}

export type UseScopeGetterResult<A extends unknown[], T> = (...args: A) => T;

export function useScopeGetter<
  A extends unknown[],
  T = { [name: string]: ScopeItemMP | undefined },
>({
  includeHidden = false,
  selector,
}: UseScopeGetterArgs<A, T> = {}): UseScopeGetterResult<A, T> {
  const { appSessionId } = useSessionContext();
  const store = useStore();
  const selectorRef = useStableRef(selector);

  return useCallback(
    (...args: A) => {
      const scopeSelectors =
        appSessionMPSelectors.getScopeSelectors(appSessionId);

      const scope = includeHidden
        ? scopeSelectors.selectEntitiesWithHidden(store.getState())
        : scopeSelectors.selectEntities(store.getState());

      if (scope == null) {
        throw new Error(`Missing scope state for app session: ${appSessionId}`);
      }

      // this cannot be conditionally chained/nullish coalesced since
      // the provided selector may intentionally return undefined
      return selectorRef.current
        ? selectorRef.current(scope, ...args)
        : (scope as unknown as T);
    },
    [appSessionId, store, includeHidden, selectorRef],
  );
}
