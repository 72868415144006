import { AppSessionCellId } from "@hex/common";
import { useCallback } from "react";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import {
  AppSessionCellMP,
  appSessionMPSelectors,
} from "../../redux/slices/appSessionMPSlice";
import { useSessionContext } from "../../util/sessionContext";

export type UseAppSessionCellSelectorArgs<T> =
  | {
      appSessionCellId: AppSessionCellId;
      selector: (appSessionCell: AppSessionCellMP) => T;
      equalityFn?: (left: T, right: T) => boolean;
      safe?: false;
    }
  | {
      appSessionCellId: AppSessionCellId | undefined;
      selector: (appSessionCell: AppSessionCellMP | undefined) => T;
      equalityFn?: (left: T, right: T) => boolean;
      safe: true;
    };

export function useAppSessionCellSelector<T>(
  args: UseAppSessionCellSelectorArgs<T>,
): T {
  const { appSessionId } = useSessionContext();

  return useSelector((state) => {
    if (args.appSessionCellId == null) {
      if (args.safe) {
        const selector = args.selector;
        return selector(undefined);
      } else {
        throw new Error(
          `Missing state for appSessionCell: ${args.appSessionCellId}`,
        );
      }
    }

    const appSessionCellState =
      appSessionMPSelectors
        .getAppSessionCellSelectors(appSessionId)
        .selectById(state, args.appSessionCellId) ?? undefined;

    if (appSessionCellState == null) {
      if (args.safe) {
        const selector = args.selector;
        return selector(undefined);
      } else {
        throw new Error(
          `Missing state for appSessionCell: ${args.appSessionCellId}`,
        );
      }
    }

    const selector = args.selector;
    return selector(appSessionCellState);
  }, args.equalityFn);
}

interface UseUnsafeAppSessionCellGetterArgs<A extends unknown[], T> {
  selector?: (appSessionCell: AppSessionCellMP, ...args: A) => T;
  safe?: false;
}
interface UseSafeAppSessionCellGetterArgs<A extends unknown[], T> {
  selector?: (appSessionCell: AppSessionCellMP | undefined, ...args: A) => T;
  safe: true;
}
export type UseAppSessionCellGetterArgs<A extends unknown[], T> =
  | UseUnsafeAppSessionCellGetterArgs<A, T>
  | UseSafeAppSessionCellGetterArgs<A, T>;

type UseUnsafeAppSessionCellGetterResult<A extends unknown[], T> = (
  appSessionCellId: AppSessionCellId,
  ...args: A
) => T;
type UseSafeAppSessionCellGetterResult<A extends unknown[], T> = (
  appSessionCellId: AppSessionCellId | undefined,
  ...args: A
) => T;
export type UseAppSessionCellGetterResult<A extends unknown[], T> =
  | UseUnsafeAppSessionCellGetterResult<A, T>
  | UseSafeAppSessionCellGetterResult<A, T>;

export function useAppSessionCellGetter<
  A extends unknown[],
  T = AppSessionCellMP,
>(
  args?: UseUnsafeAppSessionCellGetterArgs<A, T>,
): UseUnsafeAppSessionCellGetterResult<A, T>;
export function useAppSessionCellGetter<
  A extends unknown[],
  T = AppSessionCellMP | undefined,
>(
  args: UseSafeAppSessionCellGetterArgs<A, T>,
): UseSafeAppSessionCellGetterResult<A, T>;
export function useAppSessionCellGetter<A extends unknown[], T>(
  args: UseAppSessionCellGetterArgs<A, T | undefined | AppSessionCellMP> = {},
): UseAppSessionCellGetterResult<A, T | undefined | AppSessionCellMP> {
  const { appSessionId } = useSessionContext();
  const store = useStore();
  const selectorRef = useStableRef(args.selector);

  return useCallback(
    (appSessionCellId: AppSessionCellId | undefined, ...cbArgs: A) => {
      if (appSessionCellId == null) {
        if (args.safe) {
          const selector =
            selectorRef.current as UseSafeAppSessionCellGetterArgs<
              A,
              T
            >["selector"];
          return selector?.(undefined, ...cbArgs);
        } else {
          throw new Error(
            `Missing state for appSessionCell: ${appSessionCellId}`,
          );
        }
      }

      const appSessionCell = appSessionMPSelectors
        .getAppSessionCellSelectors(appSessionId)
        .selectById(store.getState(), appSessionCellId);

      if (appSessionCell == null) {
        if (args.safe) {
          const selector =
            selectorRef.current as UseSafeAppSessionCellGetterArgs<
              A,
              T
            >["selector"];
          return selector?.(undefined, ...cbArgs);
        } else {
          throw new Error(
            `Missing state for appSessionCell: ${appSessionCellId}`,
          );
        }
      }

      // this cannot be conditionally chained/nullish coalesced since
      // the provided selector may intentionally return undefined
      const selector = selectorRef.current;
      return selector ? selector(appSessionCell, ...cbArgs) : appSessionCell;
    },
    [appSessionId, store, selectorRef, args.safe],
  );
}
