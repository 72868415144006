import type { GenericAtomicOperation } from "@hex/common";
import { uuid } from "@hex/common";
import React, {
  ComponentType,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import ReactDOM from "react-dom";

import type { AtomicOperationController } from "./AtomicOperationController.js";

type ControllerStatus<
  T extends GenericAtomicOperation = GenericAtomicOperation,
> =
  | {
      type: "error";
      controller: undefined;
    }
  | {
      type: "loading";
      controller: undefined;
    }
  | {
      type: "success";
      controller: AtomicOperationController<T>;
    };

type SubscriptionCallback = (newStatus: ControllerStatus | undefined) => void;

/**
 * Stateful class for managing references and loading statuses of controllers
 */
class AtomicControllerRegistry {
  /**
   * Map of multiplayer model id to current status + controller
   *
   * Record<MultiplayerId, ControllerStatus | undefined>
   */
  private controllers: Record<string, ControllerStatus | undefined> = {};

  /**
   * Map of multiplayer model id to a map of subscriber id to subscription function
   *
   * Record<MultiplayerId, Record<SubscriberId, SubscriptionCallback>>
   */
  private subscribers: Record<string, Record<string, SubscriptionCallback>> =
    {};

  /**
   * Get current controller status
   */
  public get = (modelId: string): ControllerStatus | undefined => {
    return this.controllers[modelId];
  };

  /**
   * Set the current controller status and notify all subscribers
   */
  public set = (
    modelId: string,
    newStatus: ControllerStatus | undefined,
  ): void => {
    if (newStatus == null) {
      delete this.controllers[modelId];
    } else {
      this.controllers[modelId] = newStatus;
    }
    const subscriberMap = this.subscribers[modelId] ?? {};
    for (const callback of Object.values(subscriberMap)) {
      callback(newStatus);
    }
  };

  /**
   * Subscribe to any changes to given controller
   * @returns unsubcribe callback
   */
  public subscribe(
    modelId: string,
    callback: SubscriptionCallback,
  ): () => void {
    const subscriberId = uuid();
    const currentMap = (this.subscribers[modelId] ??= {});
    currentMap[subscriberId] = callback;
    return () => {
      delete this.subscribers[modelId][subscriberId];
    };
  }
}

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- create context
const AtomicControllerContextInternal = createContext<
  AtomicControllerRegistry | undefined
>(undefined);
AtomicControllerContextInternal.displayName = "AtomicControllerContext";

export const AtomicControllerContext: ComponentType<{
  children: ReactNode;
}> = function AtomicControllerContext({ children }) {
  const [registry] = useState(() => new AtomicControllerRegistry());
  return (
    <AtomicControllerContextInternal.Provider value={registry}>
      {children}
    </AtomicControllerContextInternal.Provider>
  );
};

export const useController = <T extends GenericAtomicOperation>(
  modelId: string,
): ControllerStatus<T> | undefined => {
  const registry = useContext(AtomicControllerContextInternal);
  const [status, setStatus] = useState(() => registry?.get(modelId));

  const [previousModelId, setPreviousModelId] = useState(modelId);
  if (modelId !== previousModelId) {
    ReactDOM.unstable_batchedUpdates(() => {
      setPreviousModelId(modelId);
      setStatus(registry?.get(modelId));
    });
  }

  useEffect(() => {
    const unsubscribe = registry?.subscribe(modelId, (newStatus) => {
      setStatus(newStatus);
    });
    return () => {
      unsubscribe?.();
    };
  }, [modelId, registry]);

  return status;
};

export const useGetController = <T extends GenericAtomicOperation>(): ((
  modelId: string,
) => ControllerStatus<T> | undefined) => {
  const registry = useContext(AtomicControllerContextInternal);

  return useCallback(
    (modelId) => {
      return registry?.get(modelId);
    },
    [registry],
  );
};

export const useSetController = <T extends GenericAtomicOperation>(): ((
  modelId: string,
  newStatus: ControllerStatus<T> | undefined,
) => void) => {
  const registry = useContext(AtomicControllerContextInternal);

  return useCallback(
    (modelId, newStatus) => {
      return registry?.set(modelId, newStatus as ControllerStatus);
    },
    [registry],
  );
};
