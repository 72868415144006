import { AppSessionCellId } from "@hex/common";
import { useCallback } from "react";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import {
  AppSessionCellMP,
  appSessionMPSelectors,
} from "../../redux/slices/appSessionMPSlice";
import { useSessionContext } from "../../util/sessionContext";

export interface UseAppSessionCellsSelectorArgs<T> {
  selector: (
    appSessionCells: Record<AppSessionCellId, AppSessionCellMP | undefined>,
  ) => T;
  equalityFn?: (left: T, right: T) => boolean;
}

export function useAppSessionCellsSelector<T>({
  equalityFn,
  selector,
}: UseAppSessionCellsSelectorArgs<T>): T {
  const { appSessionId } = useSessionContext();

  return useSelector((state) => {
    const appSessionCellsState = appSessionMPSelectors
      .getAppSessionCellSelectors(appSessionId)
      .selectEntities(state);

    if (appSessionCellsState == null) {
      throw new Error(
        `Missing appSessionCells state for app session: ${appSessionId}`,
      );
    }

    return selector(appSessionCellsState);
  }, equalityFn);
}

export interface UseAppSessionCellsGetterArgs<A extends unknown[], T> {
  selector?: (
    appSessionCells: Record<AppSessionCellId, AppSessionCellMP | undefined>,
    ...args: A
  ) => T;
}

export type UseAppSessionCellsGetterResult<A extends unknown[], T> = (
  ...args: A
) => T;

export function useAppSessionCellsGetter<
  A extends unknown[],
  T = Record<AppSessionCellId, AppSessionCellMP | undefined>,
>({
  selector,
}: UseAppSessionCellsGetterArgs<A, T> = {}): UseAppSessionCellsGetterResult<
  A,
  T
> {
  const { appSessionId } = useSessionContext();
  const store = useStore();
  const selectorRef = useStableRef(selector);

  return useCallback(
    (...args: A) => {
      const appSessionCells = appSessionMPSelectors
        .getAppSessionCellSelectors(appSessionId)
        .selectEntities(store.getState());

      if (appSessionCells == null) {
        throw new Error(
          `Missing appSessionCells state for app session: ${appSessionId}`,
        );
      }

      // this cannot be conditionally chained/nullish coalesced since
      // the provided selector may intentionally return undefined
      return selectorRef.current
        ? selectorRef.current(appSessionCells, ...args)
        : (appSessionCells as unknown as T);
    },
    [appSessionId, store, selectorRef],
  );
}
