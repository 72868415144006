import { AppSessionId } from "@hex/common";
import { useCallback } from "react";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import {
  AppSessionMP,
  appSessionMPSelectors,
} from "../../redux/slices/appSessionMPSlice";
import { useSessionContext } from "../../util/sessionContext";

export interface UseAppSessionSelectorArgs<T> {
  /** By default, the `appSessionId` from the session context will be used.
     Only supply this argument in the very rare case that you want data from a different app session. */
  appSessionId?: AppSessionId;
  selector: (appSession: AppSessionMP) => T;
  equalityFn?: (left: T, right: T) => boolean;
}

export function useAppSessionSelector<T>({
  appSessionId: appSessionIdArg,
  equalityFn,
  selector,
}: UseAppSessionSelectorArgs<T>): T {
  const { appSessionId } = useSessionContext();

  return useSelector((state) => {
    const appSession = appSessionMPSelectors
      .getAppSessionSelectors(appSessionIdArg ?? appSessionId)
      .select(state);

    return selector(appSession);
  }, equalityFn);
}

export interface UseAppSessionGetterArgs<A extends unknown[], T> {
  selector?: (appSession: AppSessionMP, ...args: A) => T;
}

export type UseAppSessionGetterResult<A extends unknown[], T> = (
  ...args: A
) => T;

export function useAppSessionGetter<A extends unknown[], T = AppSessionMP>({
  selector,
}: UseAppSessionGetterArgs<A, T> = {}): UseAppSessionGetterResult<A, T> {
  const { appSessionId } = useSessionContext();
  const store = useStore();
  const selectorRef = useStableRef(selector);

  return useCallback(
    (...args: A) => {
      const appSession = appSessionMPSelectors
        .getAppSessionSelectors(appSessionId)
        .select(store.getState());

      // this cannot be conditionally chained/nullish coalesced since
      // the provided selector may intentionally return undefined
      return selectorRef.current
        ? selectorRef.current(appSession, ...args)
        : (appSession as unknown as T);
    },
    [appSessionId, store, selectorRef],
  );
}
